<template>
  <base-card>
    <base-label text="Disparo teste" />
    <p class="enter-phone">
      Informe o seu número de telefone para que possamos realizar um disparo
      teste.
    </p>

    <div class="fire-test-message">
      <div class="d-flex mb_25 justify-center">
        <base-input
          design="one"
          class="mr_15"
          center
          :disabled="isWaitingSending"
          v-model="testPhone.ddd"
          placeholder="00"
          :max="2"
          :masked="true"
          :mask="StringLanguage.dddMask"
          type="number"
          style="width: 75px"
        />
        <base-input
          design="one"
          center
          :disabled="isWaitingSending"
          v-model="testPhone.phone"
          placeholder="00000-0000"
          :masked="true"
          :mask="StringLanguage.phoneWithoutDdd"
          style="width: 190px"
          ref="phone"
        />
      </div>
      <base-button
        design="three"
        class="w-100"
        :disabled="isWaitingSending || !validMessage() || !validPhone"
        @click="sendSmsTest"
      >
        Disparar
        <span v-if="remainTime > 0">
            &nbsp;({{`${`${Math.floor(remainTime / 60)}`.padStart(2, '0')}:${`${remainTime % 60}`.padStart(2, '0')}`}})
        </span>
      </base-button>
    </div>
  </base-card>
</template>

<script>
import BaseLabel from '@/components/Label';
import BaseInput from '@/components/Input';
import BaseCard from '@/components/Card';
import BaseButton from '@/components/Button';

import { TestShootingAPI, TestShootingSmsBody } from '@/api/TestShootingAPI';

const components = {
  BaseLabel,
  BaseInput,
  BaseCard,
  BaseButton,
};

export default {
  components: components,
  props: {
    message: { type: String, default: '' },
    sender: { type: String, default: '' },
    url: { type: Object, default: null },
    beneficio: { type: Object },
    valorrecebido: { type: [String, Number], default: 0 },
  },
  data() {
    return {
      isWaitingSending: false,
      remainTime: 0,
      testPhone: {
        ddd: '',
        phone: '',
      },
      validPhone: false,
    };
  },
  methods: {
    async sendSmsTest() {
      this.isWaitingSending = true;
      const response = await TestShootingAPI.postSms(
        new TestShootingSmsBody({
          phone: `${this.testPhone.ddd}${this.testPhone.phone.replace(/[^\d+]/g, '')}`,
          message: `${this.sender}${this.$props.message}`,
          replacements: {
            url: this.$props.url,
            beneficio: this.$props.beneficio,
            valorrecebido: this.$props.valorrecebido,
          },
        })
      );

      if (response.getCode(500) != 200) {
        alert('Ocorreu um erro ao disparar a mensagem de teste');
        this.isWaitingSending = false;
        return;
      }

      this.remainTime = 180;
      const intervalId = setInterval(() => {
        this.remainTime--;
        if (this.remainTime == 0) {
          clearInterval(intervalId);
          this.isWaitingSending = false;
        }
      }, 1000);
    },
    validMessage() {
      return this.message.length > 0;
    },
  },
  watch: {
    testPhone: {
      deep: true,
      handler(newValue) {
        this.validPhone =
          newValue.ddd.length === 2 && newValue.phone.length >= 10;
        if (newValue.ddd.length === 2) this.$refs.phone.focus();
      },
    },
  },
};
</script>

<style scoped>
.enter-phone {
  font-size: 12px;
  line-height: 14px;
  color: rgba(170, 170, 171, 0.75);
  margin-bottom: 20px;
}

.fire-test-message {
  background: rgba(46, 52, 56, 0.3);
  border-radius: 10px;
  padding: 20px;
}
</style>
<template>
  <div class="qrcode-container">
    <svg
      width="28"
      height="19"
      viewBox="0 0 28 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5216 0.426922C28.1595 0.999807 28.1595 1.92618 27.5216 2.44421L11.0124 18.5338C10.4808 19.1554 9.53032 19.1554 8.94249 18.5338L0.439558 10.2452C-0.146519 9.72717 -0.146519 8.80081 0.439558 8.22792C1.02557 7.66113 1.9761 7.66113 2.56205 8.22792L10.0056 15.4865L25.4517 0.426922C26.0395 -0.142307 26.9901 -0.142307 27.5216 0.426922Z"
        fill="#82D997"
      />
    </svg>

    <p>Você já pode divulgá-lo informando o seguinte código:</p>
    <p>
      <span class="code">{{ benefit.code }}-{{ benefit.pin }}</span> ou então
      utilizando o <strong>QR Code</strong> abaixo:
    </p>

    <div class="qrcode" v-if="!loading && qrcode">
      <img :src="qrcode" class="img-fluid mobile" alt="Integrações" />
      <base-button design="five" @click="downloadQrCode()"
        >Salvar QR Code</base-button
      >
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Button.vue';
import { QRCode } from '@/plugins/qrcode';
import { EnvironmentsAPI } from '@/api/Environments';

export default {
  mounted() {
    const benefit = this.benefits;
    if(benefit && benefit.code && benefit.pin) {
        this.generateQrCode(benefit.code, benefit.pin);
    }
  },
  data() {
    return {
      loading: false,
      step: 0,
      urlStore: '',
      validate: true,
      showCodeLimit: false,
      showQrCode: false,
      qrcode: null,
      benefit: this.benefits,
      benefitTypes: {
        discount: 'CASH_DISCOUNT',
        percent: 'PERCENTAGE_DISCOUNT',
        delivery: 'FREE_DELIVERY',
      },
      useTypes: {},
    };
  },
  methods: {
    async generateQrCode(code, pin) {
      this.loading = true;
      const { _data } = await EnvironmentsAPI.getConfigurations(false);
      this.urlStore = `${_data['short-urls'].rawShortWallet}?code=${code}&pin=${pin}`;
      this.qrcode = await QRCode.getDataUrl(this.urlStore);
      this.loading = false;
    },
    async downloadQrCode() {
      QRCode.downloadQRCodeImage(`qrcode-${this.benefits.code}-${this.benefits.pin}.png`, this.urlStore);
    },
  },
  components: {
    BaseButton,
  },
  props: {
    benefits: { type: Object }
  }
};
</script>

<style scoped>
.grid-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.grid-options .option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--box-up);
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
}

.grid-options .option i {
  color: #6f767e;
  font-size: 25px;
  margin-bottom: 15px;
}

.grid-options .option p {
  color: #aaaaab;
  font-size: 12px;
  line-height: 15px;
}

.grid-options .option.active :is(p, i) {
  color: var(--default);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 350px);
}

.qrcode-container {
  padding: 30px 20px 20px;
  background: #2d3338;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-container p {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(204, 204, 204, 0.6);
  margin-top: 25px;
  text-align: center;
}

.qrcode-container p:nth-child(3) {
  margin-bottom: 15px;
  padding-bottom: 20px;
  margin-top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
}

.qrcode-container p span.code {
  font-weight: 900;
  color: var(--default);
}

.qrcode-container p strong {
  font-weight: 900;
  color: rgba(204, 204, 204, 0.9);
}

.qrcode-container .qrcode {
  padding: 35px 20px;
  background: #32393d;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-container .qrcode button {
  margin-top: 25px;
  width: 100%;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-card',[_c('base-label',{attrs:{"text":"Insira o código do benefício"}}),_c('base-input',{staticClass:"mb_0",attrs:{"label":"Código do benefício","maxlength":"10"},on:{"keyup":function($event){return _vm.selectBenefit(_vm.benefit)},"blur":function($event){return _vm.selectBenefit(_vm.benefit)}},model:{value:(_vm.benefit.code),callback:function ($$v) {_vm.$set(_vm.benefit, "code", $$v)},expression:"benefit.code"}}),(_vm.showCodeLimit)?_c('base-message',{staticClass:"mt_10"},[_vm._v("\n      Limite de 10 caracteres\n    ")]):_vm._e()],1),_c('base-card',[_c('base-label',{attrs:{"text":"Determine o tipo de cupom"}}),_c('base-radio',{staticClass:"mb_15",attrs:{"label":"Uso único","family":"sizeslocation","value":_vm.benefit.single_use,"option":true},on:{"click":function($event){_vm.benefit.single_use = true}}}),_c('base-radio',{attrs:{"label":"Uso múltiplo","family":"sizeslocation","value":_vm.benefit.single_use,"option":false},on:{"click":function($event){_vm.benefit.single_use = false}}})],1),_c('base-card',[_c('base-label',{attrs:{"text":"Determine o valor do benefício e o pedido mínimo"}}),(_vm.benefits.type != 'FREE_DELIVERY')?_c('div',{staticClass:"d-flex"},[_c('base-input',{staticClass:"w-100 mb_0",attrs:{"label":"Valor do beneficio","money-mask":['PERCENTAGE_DISCOUNT', 'CASHBACK'].indexOf(_vm.benefits.type) !== -1
            ? _vm.StringLanguage.percentConfigs
            : _vm.StringLanguage.currencyConfigs,"max":10},on:{"keyup":function($event){return _vm.selectBenefit(_vm.benefit)}},model:{value:(_vm.benefit.value),callback:function ($$v) {_vm.$set(_vm.benefit, "value", $$v)},expression:"benefit.value"}}),_c('base-input',{staticClass:"w-100 ml_15 mb_0",attrs:{"label":"Pedido mínimo","money-mask":_vm.StringLanguage.currencyConfigs},on:{"keyup":function($event){return _vm.selectBenefit(_vm.benefit)}},model:{value:(_vm.benefit.min_order),callback:function ($$v) {_vm.$set(_vm.benefit, "min_order", $$v)},expression:"benefit.min_order"}})],1):_c('div',{staticClass:"d-flex"},[_c('base-input',{staticClass:"w-100 mb_0",attrs:{"label":"Pedido mínimo","money-mask":_vm.StringLanguage.currencyConfigs},on:{"keyup":function($event){return _vm.selectBenefit(_vm.benefit)}},model:{value:(_vm.benefit.min_order),callback:function ($$v) {_vm.$set(_vm.benefit, "min_order", $$v)},expression:"benefit.min_order"}})],1)],1),_c('base-card',[_c('base-label',{attrs:{"text":_vm.benefits.type === 'CASHBACK'
          ? 'Defina a expiração de ganho e de gasto deste benefício'
          : 'Defina a expiração do benefício'}}),_c('base-input',{attrs:{"placeholder":_vm.benefits.type === 'CASHBACK'
          ? '--- dias de expiração de ganho'
          : '--- dias',"thousandSeparator":false,"moneyMask":_vm.benefits.type === 'CASHBACK'
          ? _vm.StringLanguage.timeConfigs.days_expiration : _vm.StringLanguage.timeConfigs.days,"max":"35"},on:{"keyup":function($event){return _vm.selectBenefit(_vm.benefit)}},model:{value:(_vm.benefit.expiration_days),callback:function ($$v) {_vm.$set(_vm.benefit, "expiration_days", $$v)},expression:"benefit.expiration_days"}}),(_vm.benefits.type === 'CASHBACK')?_c('base-input',{attrs:{"placeholder":"--- dias de expiração de gasto","thousandSeparator":false,"moneyMask":_vm.StringLanguage.timeConfigs.days_spend,"max":"35"},on:{"keyup":function($event){return _vm.selectBenefit(_vm.benefit)}},model:{value:(_vm.benefit.spend_days),callback:function ($$v) {_vm.$set(_vm.benefit, "spend_days", $$v)},expression:"benefit.spend_days"}}):_vm._e(),_c('base-message',[_vm._v("\n      A expiração deste benefício inicia a partir do momento que o usuário o\n      recebe.\n    ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <base-label text="Onde o benefício poderá ser utilizado?" />

    <base-radio
      v-if="benefit.type !== 'FREE_DELIVERY'"
      v-model="benefit.useType"
      family="useType"
      option="offline"
      @click="selectBenefit('offline')"
    >
      <p class="radio-label">Consumo no local</p>
    </base-radio>
    <base-radio
      v-model="benefit.useType"
      family="useType"
      option="online"
      class="mt_15"
      @click="selectBenefit('online')"
    >
      <p class="radio-label">Delivery</p>
    </base-radio>
    <base-radio
      v-if="benefit.type !== 'FREE_DELIVERY'"
      v-model="benefit.useType"
      family="useType"
      option="both"
      class="mt_15"
      @click="selectBenefit('both')"
    >
      <p class="radio-label">Ambos</p>
    </base-radio>
  </div>
</template>

<script>
import BaseLabel from '@/components/Label';
import BaseRadio from '@/components/Radio.vue';

export default {
  data() {
    return {
      loading: false,
      step: 0,
      validate: true,
      showCodeLimit: false,
      showQrCode: false,
      benefit: this.benefits,
      benefitTypes: {
        discount: 'CASH_DISCOUNT',
        percent: 'PERCENTAGE_DISCOUNT',
        delivery: 'FREE_DELIVERY',
      },
      useTypes: {},
    };
  },
  methods: {
    selectBenefit(value) {
      this.benefit.use_type = value;
      this.$emit('addPayload', { use_type: value });
    },
  },

  props: {
    benefits: { type: Object },
  },
  components: {
    BaseLabel,
    BaseRadio,
  },
  watch: {
    benefits: {
      deep: true,
      handler(value) {
        this.benefit = value
      }
    }
  }
};
</script>

<style scoped>
.grid-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.grid-options .option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--box-up);
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
}

.grid-options .option i {
  color: #6f767e;
  font-size: 25px;
  margin-bottom: 15px;
}

.grid-options .option p {
  color: #aaaaab;
  font-size: 12px;
  line-height: 15px;
}

.grid-options .option.active :is(p, i) {
  color: var(--default);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 350px);
}

.qrcode-container {
  padding: 30px 20px 20px;
  background: #2d3338;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-container p {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(204, 204, 204, 0.6);
  margin-top: 25px;
  text-align: center;
}

.qrcode-container p:nth-child(3) {
  margin-bottom: 15px;
  padding-bottom: 20px;
  margin-top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
}

.qrcode-container p span.code {
  font-weight: 900;
  color: var(--default);
  text-transform: uppercase;
}

.qrcode-container p strong {
  font-weight: 900;
  color: rgba(204, 204, 204, 0.9);
}

.qrcode-container .qrcode {
  padding: 35px 20px;
  background: #32393d;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-container .qrcode button {
  margin-top: 25px;
  width: 100%;
}
</style>

<template>
  <base-drawer
    :title="getTitle()"
    :open="open"
    :close="close"
    :goBack="step === 2 || (step === 3 && error)"
    :back="step === 2 || (step === 3 && error)"
    @onBack="step = 1"
    @onClose="resetDrawer()"
    :closeInFade="false"
  >
    <base-card>
      <div v-if="step === 1">
        <base-label text="Plataforma da fonte" class="mb_20" />

        <base-radio
          v-for="(source, i) in availableSources"
          :key="`available-source-${i}`"
          :label="source.name"
          family="sources"
          v-model="sourceSelected"
          :option="source.alias"
          class="mb_20"
        />

        <base-label text="Permissões do token" class="mb_20" />

        <BaseCheckbox
          v-for="(permission, i) in scopesMap"
          :key="`permission-${i}`"
          :label="permission"
          @click="addPermission(permission.key)"
          :option="permission"
          class="mb_20"
        >
          {{ permission.description }}
        </BaseCheckbox>
      </div>

      <div v-if="step === 2">
        <div v-if="!drawer.loading">
          <div class="tokens">
            <p>CLIENT ID</p>
            <div class="code">{{ tokens.client_id }}</div>
            <p>CLIENT SECRET</p>
            <div class="code">{{ tokens.client_secret }}</div>
            <p v-if="['e-deploy', 'konv-api'].includes(tokens.origin) && tokens.api_key">
              API KEY
            </p>
            <div
              v-if="['e-deploy', 'konv-api'].includes(tokens.origin) && tokens.api_key"
              class="code"
            >{{tokens.api_key}}</div>
          </div>
        </div>

        <div v-else class="loading">
          <base-loading color="#6161ff" />
        </div>
      </div>

      <div v-if="step === 3">
        <div class="status-import">
          <i class="far fa-check success" v-if="!error" />
          <i class="far fa-times error" v-else />

          <p v-if="!error">Agora você pode concluir as configurações.</p>
          <p v-else>Um erro inesperado aconteceu. Tente novamente.</p>
        </div>
      </div>
    </base-card>

    <template slot="footer">
      <base-button
        v-if="step === 1"
        class="w-100"
        @click="next()"
        :disabled="!sourceSelected || drawer.loading"
        >Gerar token</base-button
      >
      <div
        class="d-flex flex-column align-center"
        v-if="step === 2 && !drawer.loading"
      >
        <base-checkbox v-model="done" :checked="done" class="mb_15"
          >Já realizei as instruções</base-checkbox
        >
        <base-button
          class="w-100"
          :disabled="!done || drawer.loading"
          @click="activateSource()"
          >Ativar e iniciar importação</base-button
        >
      </div>
      <div v-if="step === 3">
        <base-button v-if="!error" class="w-100" @click="finishConfig()"
          >Concluir configuração</base-button
        >
        <base-button v-else class="w-100" @click="startImport()"
          >Tentar novamente</base-button
        >
      </div>
    </template>
  </base-drawer>
</template>

<script>
import { mapState } from 'vuex';
import { EnvironmentsAPI } from '@/api/Environments';

import BaseTitle from '@/components/Title';
import BaseEmpty from '@/components/Empty';
import BaseDrawer from '@/components/Drawer';
import BaseLabel from '@/components/Label';
import BaseButton from '@/components/Button';
import BaseCard from '@/components/Card';
import BaseRadio from '@/components/Radio';
import BaseCheckbox from '@/components/Checkbox';
import BaseLoading from '@/components/Loading';
import BaseInput from '@/components/Input';

const components = {
  BaseTitle,
  BaseEmpty,
  BaseDrawer,
  BaseLabel,
  BaseButton,
  BaseCard,
  BaseRadio,
  BaseCheckbox,
  BaseLoading,
  BaseInput,
};

export default {
  components,
  props: {
    unit: { type: Object, required: true },
    open: { type: Boolean },
    close: { type: Boolean },
  },
  async mounted() {
    this.availableSources = (
      await EnvironmentsAPI.getAvailableSources()
    ).getData([]);
  },

  data() {
    return {
      sourceSelected: '',
      permissionsSelected: [],
      checked: false,
      done: false,
      error: false,
      showLoading: false,
      step: 1,
      tokens: { id: null, status: null, client_id: null, client_secret: null },
      availableSources: [],
      drawer: { loading: false },
      scopesMap: [
        { key: 'DATALAKE_ORDER_INSERT', description: 'Enviar pedidos' },
        { key: 'DATALAKE_CUSTOMER_INSERT', description: 'Enviar clientes' },
      ],
    };
  },

  methods: {
    addPermission(permission) {
      const index = this.permissionsSelected.indexOf(permission);
      if (index === -1) {
        this.permissionsSelected.push(permission);
      } else {
        this.permissionsSelected.splice(index, 1);
      }
    },
    getSourceName(origin) {
      for (const source of this.availableSources) {
        if (source.alias == origin) {
          return source.name;
        }
      }
      return 'Desconhecida';
    },
    getTitle() {
      switch (this.step) {
        case 1:
          return 'Cadastrar entrada';
        case 2:
          return 'Tokens de acesso';
        case 3:
          return this.error
            ? 'Tokens de acesso'
            : 'Importação ativada com sucesso!';
        default:
          return '';
      }
    },
    resetDrawer() {
      this.error = false;
      this.step = 1;
      this.checked = false;
      this.drawer.loading = false;

      this.sourceSelected = '';
      this.tokens = {
        id: null,
        status: null,
        client_id: null,
        client_secret: null,
        origin: null,
      };
    },
    finishConfig() {
      this.error = false;
      this.step = 1;

      this.checked = false;

      this.drawer.open = false;
      this.drawer.close = !this.drawer.close;
      this.drawer.loading = false;

      this.sourceSelected = '';
      this.tokens = {
        id: null,
        status: null,
        client_id: null,
        client_secret: null,
        origin: null,
      };
      this.$emit('close');
    },
    async activateSource(tokens) {
      this.drawer.loading = true;
      this.error = false;
      const response = await EnvironmentsAPI.setSourceStatus(
        this.unit.id,
        this.tokens.id,
        'ACTIVATED',
        false
      );
      if (response.getErrors([]).length == 0) {
        this.drawer.loading = false;
        this.step = 3;
        this.$emit('new-source');
      } else {
        alert('Não foi possível ativar a fonte de dados');
      }
    },
    async next() {
      if (this.sourceSelected) {
        this.drawer.loading = true;
        const payload = {
          origin: this.sourceSelected,
          scopes: this.permissionsSelected,
        };
        const response = await EnvironmentsAPI.addSource(
          this.unit.id,
          payload,
          false
        );
        if (response.getErrors([]).length == 0) {
          this.error = false;
          const tokens = response.getData();
          this.tokens.id = tokens.id;
          this.tokens.client_id = tokens.client_id;
          this.tokens.client_secret = tokens.client_secret;
          this.tokens.api_key = tokens.api_key;
          this.tokens.origin = tokens.origin;
          this.tokens.status = tokens.status;
          this.step = 2;
        } else {
          this.error = true;
        }

        this.drawer.loading = false;
        return;
      }
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>

<style scoped>
.sources {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.sources .source {
  background: var(--box);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sources .source .logo {
  width: 50px;
  height: 50px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  border-radius: 10px;
  background: #333a4059;
}

.sources .source .logo img {
  max-width: 100%;
}

.sources .source .name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 15px;
  color: #aaaaab;
}

.tokens {
  background: #2d3338;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 35px;
}

.tokens p {
  color: #aaaaab;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 20px;
}

.tokens p:not(:first-child) {
  border-top: 1px solid #333a40;
  padding-top: 20px;
  margin-top: 20px;
  width: 100%;
}

.tokens .code {
  word-break: break-all;
  max-width: 100%;
  background: #282d30;
  color: #cccccc;
  font-size: 12px;
  line-height: 14px;
  border-radius: 10px;
  opacity: 0.8;
  padding: 15px 13px;
  display: flex;
  align-items: center;
}

.instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.instructions p {
  color: #aaaaab;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 17px;
}

.instructions a {
  color: var(--default);
  text-decoration: none;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 4px;
  border-bottom: 1px solid;
}

.status-import {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #2d3338;
  padding: 30px 15px;
  border-radius: 10px;
}

.status-import i {
  font-size: 30px;
  margin-bottom: 25px;
}

.status-import i.success {
  color: #82d997;
}

.status-import i.error {
  color: #6f767e;
}

.status-import p {
  color: #cccccc;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  opacity: 0.75;
}

.loading {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .sources {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}
</style>

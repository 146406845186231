var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-card',[_c('base-label',{attrs:{"text":"Determine o benefício"}}),_c('div',{staticClass:"grid-options"},[_c('div',{staticClass:"option",class:{
        active: _vm.benefit.type === _vm.benefitTypes.discount,
      },on:{"click":function($event){return _vm.selectBenefit(_vm.benefitTypes.discount)}}},[(_vm.benefit.type !== _vm.benefitTypes.discount)?_c('i',{staticClass:"far fa-sack-dollar"}):_vm._e(),(_vm.benefit.type === _vm.benefitTypes.discount)?_c('i',{staticClass:"far fa-check"}):_vm._e(),_c('p',[_vm._v("Desconto em R$")])]),_c('div',{staticClass:"option",class:{
        active: _vm.benefit.type === _vm.benefitTypes.percent,
      },on:{"click":function($event){return _vm.selectBenefit(_vm.benefitTypes.percent)}}},[(_vm.benefit.type !== _vm.benefitTypes.percent)?_c('i',{staticClass:"far fa-percent"}):_vm._e(),(_vm.benefit.type === _vm.benefitTypes.percent)?_c('i',{staticClass:"far fa-check"}):_vm._e(),_c('p',[_vm._v("Desconto em %")])]),_c('div',{staticClass:"option",class:{
        active: _vm.benefit.type === _vm.benefitTypes.delivery,
      },on:{"click":function($event){return _vm.selectBenefit(_vm.benefitTypes.delivery)}}},[(_vm.benefit.type !== _vm.benefitTypes.delivery)?_c('i',{staticClass:"far fa-motorcycle"}):_vm._e(),(_vm.benefit.type === _vm.benefitTypes.delivery)?_c('i',{staticClass:"far fa-check"}):_vm._e(),_c('p',[_vm._v("Entrega grátis")])]),_c('div',{staticClass:"option",class:{ active: _vm.benefit.type === _vm.benefitTypes.cashback },on:{"click":function($event){return _vm.selectBenefit(_vm.benefitTypes.cashback)}}},[(_vm.benefit.type !== _vm.benefitTypes.cashback)?_c('i',{staticClass:"far fa-hand-holding-dollar"}):_vm._e(),(_vm.benefit.type === _vm.benefitTypes.cashback)?_c('i',{staticClass:"far fa-check"}):_vm._e(),_c('p',[_vm._v("Cashback")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
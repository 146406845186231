<template>
  <base-card>
    <base-label text="Determine o benefício" />
    <div class="grid-options">
      <div
        class="option"
        :class="{
          active: benefit.type === benefitTypes.discount,
        }"
        @click="selectBenefit(benefitTypes.discount)"
      >
        <i
          class="far fa-sack-dollar"
          v-if="benefit.type !== benefitTypes.discount"
        />
        <i class="far fa-check" v-if="benefit.type === benefitTypes.discount" />
        <p>Desconto em R$</p>
      </div>

      <div
        class="option"
        :class="{
          active: benefit.type === benefitTypes.percent,
        }"
        @click="selectBenefit(benefitTypes.percent)"
      >
        <i
          class="far fa-percent"
          v-if="benefit.type !== benefitTypes.percent"
        />
        <i class="far fa-check" v-if="benefit.type === benefitTypes.percent" />
        <p>Desconto em %</p>
      </div>

      <div
        class="option"
        :class="{
          active: benefit.type === benefitTypes.delivery,
        }"
        @click="selectBenefit(benefitTypes.delivery)"
      >
        <i
          class="far fa-motorcycle"
          v-if="benefit.type !== benefitTypes.delivery"
        />
        <i class="far fa-check" v-if="benefit.type === benefitTypes.delivery" />
        <p>Entrega grátis</p>
      </div>

      <div
        class="option"
        :class="{ active: benefit.type === benefitTypes.cashback }"
        @click="selectBenefit(benefitTypes.cashback)"
      >
        <i
          class="far fa-hand-holding-dollar"
          v-if="benefit.type !== benefitTypes.cashback"
        />
        <i class="far fa-check" v-if="benefit.type === benefitTypes.cashback" />
        <p>Cashback</p>
      </div>
    </div>
  </base-card>
</template>

<script>
import BaseLabel from '@/components/Label';
import BaseCard from '@/components/Card';

export default {
  data() {
    return {
      loading: false,
      step: 0,
      validate: true,
      showCodeLimit: false,
      showQrCode: false,
      benefit: this.benefits,
      benefitTypes: {
        discount: 'CASH_DISCOUNT',
        percent: 'PERCENTAGE_DISCOUNT',
        delivery: 'FREE_DELIVERY',
        cashback: 'CASHBACK',
      },
      useTypes: {},
    };
  },
  methods: {
    selectBenefit(value) {
      this.benefit.type = value;
      this.$emit('addPayload', { type: value });
    },
  },

  props: {
    benefits: { type: Object },
  },
  components: {
    BaseLabel,
    BaseCard,
  },
  watch: {
    benefits: {
      deep: true,
      handler(value) {
        this.benefit = value;
      },
    },
  },
};
</script>

<style scoped>
.grid-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.grid-options .option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--box-up);
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
}

.grid-options .option i {
  color: #6f767e;
  font-size: 25px;
  margin-bottom: 15px;
}

.grid-options .option p {
  color: #aaaaab;
  font-size: 12px;
  line-height: 15px;
}

.grid-options .option.active :is(p, i) {
  color: var(--default);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 350px);
}

.qrcode-container {
  padding: 30px 20px 20px;
  background: #2d3338;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-container p {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(204, 204, 204, 0.6);
  margin-top: 25px;
  text-align: center;
}

.qrcode-container p:nth-child(3) {
  margin-bottom: 15px;
  padding-bottom: 20px;
  margin-top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
}

.qrcode-container p span.code {
  font-weight: 900;
  color: var(--default);
  text-transform: uppercase;
}

.qrcode-container p strong {
  font-weight: 900;
  color: rgba(204, 204, 204, 0.9);
}

.qrcode-container .qrcode {
  padding: 35px 20px;
  background: #32393d;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-container .qrcode button {
  margin-top: 25px;
  width: 100%;
}
</style>

/* eslint-disable */
import Vue from 'vue';
import App from './App.jsx';
import router, { notAllowedRoutesOlga } from './router';
import store from './store';
import money from 'v-money';
import { sync } from 'vuex-router-sync';
import { getXUserKey, setXUserKey } from './plugins/account';
import { Internationalization } from './utils/Internationalization';
import dayjs from 'dayjs'
import { OAuthAPI } from './api/OAuth.js';

Vue.config.devtools = true;
window.StringLanguage = Internationalization.getDataByLanguage('pt-BR');
Vue.prototype.StringLanguage = Internationalization.getDataByLanguage('pt-BR');

window.URL_API_SERVICE = process.env.VUE_APP_URL_API_SERVICE;

router.beforeEach(async (to, from, next) => {

  const accessToken = getXUserKey();
  //Se o usuário estiver autenticado
  if (accessToken) {
    //Obter os dados do jwt
    const middlePortion = accessToken.split('.')[1]
    const jwtBody = JSON.parse(Buffer.from(middlePortion, 'base64').toString());

    const daysToExpire = dayjs(jwtBody.exp * 1000).diff(dayjs(), "days");
    //Se o token já expirou
    if (daysToExpire < 0) {
      setXUserKey(null);
      return router.push({ name: 'login' });
    }

    // Se a expiração for menor de 4 dias de expirar (faltam 3 dias para expirar)
    if (daysToExpire < 4) {
      const response = await OAuthAPI.refresh();
      const { access_token: newAccessToken } = response.getData({ accessToken: false });
      //Se não foi possível renovar o token
      if (!newAccessToken) {
        setXUserKey(null);
        return router.push({ name: 'login' });
      }
      setXUserKey(newAccessToken);
    }
  } else if (to.name !== 'login' && to.meta.needsAuth) {
    return router.push({ name: 'login' });
  }

  let contentDiv = document.getElementById('content');
  if (contentDiv) {
    contentDiv.scrollTop = 0;
  }

  if (notAllowedRoutesOlga.includes(to.name)) {
    return router.push({ name: 'dashboard' });
  }

  if (to.name !== 'login' && to.name !== 'AccountCreate' && to.name !== 'importador') {
    const allowedRoutes = [
      'signup',
      'forgotpassword',
      'onboard',
      'configuracoes',
      'informacoes',
      'localizacao',
      'horario',
      'regioes',
      'pagamento',
      'sucesso-cadastro',
    ];
    if (!allowedRoutes.includes(to.name)) {
      // const redirectToOnboard = !store.getters['store/isOnBoardDone'];

      // if (redirectToOnboard) {
      //   router.push({ name: 'onboard' });
      // }
    }
  }

  store.commit('store/setLoading', false);
  store.commit('store/setNoTemplate', false);

  store.dispatch('account/getIsLogged');
  store.dispatch('account/getUser');

  next();
});

sync(store, router);
Vue.use(money, { precision: 4 });

new Vue({
  router: router,
  store: store,
  render: (h) => {
    return <App />;
  },
}).$mount('#app');

<template>
  <base-drawer
    :title="getTitle()"
    :open="open"
    :close="close"
    @onClose="closeDrawer"
    :goBack="step > 1 && !loading && !showQrCode"
    :back="step > 1 && !loading && !showQrCode"
    @onBack="prevStep()"
  >
    <base-card v-if="loading" class="d-flex align-center justify-center h-100">
      <base-loading />
    </base-card>

    <base-card v-if="showQrCode">
      <show-qr-code :benefits="benefit" :pin="pin" />
    </base-card>

    <base-steps v-if="!loading && !showQrCode" :steps="4" :currentStep="step">
      <select-type-benefit
        v-if="step === 1"
        @addPayload="changePayload"
        :benefits="benefit"
      />
      <insert-code-benefit
        v-if="step === 2"
        @addPayload="changePayload"
        :benefits="benefit"
      />
      <type-consume-benefit
        v-if="step === 3"
        @addPayload="changePayload"
        :benefits="benefit"
      />
      <using-type
        v-if="step === 4"
        @addPayload="changePayload"
        :benefits="benefit"
      />
    </base-steps>

    <template slot="footer" v-if="!loading">
      <base-button
        v-if="step < 3 && !showQrCode"
        class="w-100"
        @click="nextStep()"
        :disabled="validate"
      >
        Próximo
      </base-button>
      <base-button
        v-if="step === 3"
        class="w-100"
        @click="save"
        :disabled="validate"
      >
        Concluir e gerar QR Code
      </base-button>
      <base-button v-if="showQrCode" class="w-100" @click="closeDrawer">
        Voltar para listagem de cupons
      </base-button>
    </template>
  </base-drawer>
</template>

<script>
import BaseButton from '@/components/Button';
import BaseDrawer from '@/components/Drawer';
import BaseCard from '@/components/Card';
import BaseLoading from '@/components/Loading';
import BaseSteps from '@/components/Steps';
import SelectTypeBenefit from './steps/selectTypeBenefit';
import InsertCodeBenefit from './steps/insertCodeBenefit.vue';
import typeConsumeBenefit from './steps/typeConsumeBenefit.vue';
import UsingType from './steps/usingBenefit.vue';
import showQrCode from './steps/showQrCode.vue';
import { StandaloneCouponsAPI } from '@/api/StandaloneCouponsAPI';

const useTypes = {
  ONLINE: 'online',
  OFFLINE: 'offline',
  BOTH: 'both',
};

const benefitTypes = {
  discount: 'CASH_DISCOUNT',
  percent: 'PERCENTAGE_DISCOUNT',
  delivery: 'FREE_DELIVERY',
  cashback: 'CASHBACK',
};

const usingType = { online: 'online', both: 'both', local: 'offline' };

export default {
  components: {
    BaseButton,
    BaseDrawer,
    BaseCard,
    BaseLoading,
    BaseSteps,
    SelectTypeBenefit,
    InsertCodeBenefit,
    UsingType,
    showQrCode,
    typeConsumeBenefit,
  },
  props: {
    open: { type: Boolean, default: false },
    close: { type: Boolean, default: false },
    pin: { type: String, default: '' },
    benefits: { type: Array, default: () => [] },
  },
  data() {
    return {
      loading: false,
      step: 1,
      validate: true,
      showCodeLimit: false,
      showQrCode: false,
      lastChangeTimestamp: Date.now(),
      benefit: {
        type: null,
        code: null,
        pin: this.benefits.pin || null,
        use_type: null,
        expiration_days: 0,
        spend_days: 0,
      },
      benefitTypes,
      useTypes,
    };
  },
  created() {
    this.validate = this.validateStep();
  },

  methods: {
    closeDrawer() {
      this.step = 1;

      this.$emit('onClose');
      this.showQrCode = false;

      this.mountNullObject(this.benefit);
    },
    nextStep() {
      this.step += 1;

      this.validate = this.validateStep();
    },
    prevStep() {
      this.step -= 1;

      this.validate = this.validateStep();
    },
    validateStep() {
      const typesValidate = Object.values(this.benefitTypes).includes(
        this.benefit.type
      );

      const typeUsing = Object.values(usingType).includes(
        this.benefit.use_type
      );
      switch (this.step) {
        case 1: {
          if (typesValidate) return false;
          break;
        }
        case 2: {
          let hasValue = false;
          if (
            [this.benefitTypes.percent, this.benefitTypes.cashback].includes(
              this.benefit.type
            )
          ) {
            hasValue =
              Number(this.benefit.value) > 0 &&
              Number(this.benefit.value) <= 100;
          } else {
            hasValue = Number(this.benefit.value) > 0;
          }

          if (
            (this.benefit.type !== benefitTypes.cashback &&
              (this.benefit.code || []).length > 0 &&
              Number(this.benefit.expiration_days) > 0 &&
              hasValue) ||
            (this.benefit.type === benefitTypes.cashback &&
              (this.benefit.code || []).length > 0 &&
              Number(this.benefit.spend_days) > 0 &&
              Number(this.benefit.expiration_days) > 0 &&
              hasValue)
          ) {
            return false;
          }
          break;
        }
        case 3: {
          if (typeUsing) return false;
          break;
        }
      }

      return true;
    },
    changePayload(data) {
      this.benefit = Object.assign(this.benefit, data);
      this.lastChangeTimestamp = Date.now();
      this.valid = true;
    },

    getTitle() {
      if (this.showQrCode) return 'Cupom avulso criado com sucesso!';

      if (this.benefit.type) {
        let title = '';

        switch (this.benefit.type) {
          case benefitTypes.CASH_DISCOUNT:
            title = '- Desconto em R$';
            break;
          case benefitTypes.PERCENTAGE_DISCOUNT:
            title = '- Desconto em %';
            break;
          case benefitTypes.FREE_DELIVERY:
            title = '- Entrega grátis';
            break;
          case benefitTypes.cashback:
            title = '- Cashback';
            break;
        }

        return `Criar benefício ${title}`;
      }

      return 'Criar benefício';
    },
    async save() {
      this.loading = true;

      const requestBody = {
        standalone: true,
        status: 'ACTIVATED',
        ...this.benefit,
      };

      if (requestBody.type == 'FREE_DELIVERY') {
        requestBody.value = 0;
      }

      const response = await StandaloneCouponsAPI.postStandaloneCoupons(
        requestBody
      );
      const errors = response.getErrors([]);
      if (errors.length > 0) {
        return alert(errors[0]);
      }

      const benefit = response.getData({});
      this.benefit.pin = benefit.pin;

      this.loading = false;
      this.showQrCode = true;
      this.step = 1;
      this.$emit('saved');
    },
    mountNullObject(data) {
      const props = Object.keys(data);
      const objectProps = ['configs', 'expiration', 'gain', 'spend'];

      const object = data;

      props.forEach((key) => {
        let value;

        if (objectProps.includes(key)) {
          value = this.mountNullObject(data[key]);
        } else {
          value = null;
        }

        object[key] = value;
      });

      return object;
    },
  },
  watch: {
    benefit: {
      deep: true,
      handler() {
        this.validate = this.validateStep();
      },
    },
  },
};
</script>

<style scoped>
.grid-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.grid-options .option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--box-up);
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
}

.grid-options .option i {
  color: #6f767e;
  font-size: 25px;
  margin-bottom: 15px;
}

.grid-options .option p {
  color: #aaaaab;
  font-size: 12px;
  line-height: 15px;
}

.grid-options .option.active :is(p, i) {
  color: var(--default);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 350px);
}

.qrcode-container {
  padding: 30px 20px 20px;
  background: #2d3338;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-container p {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(204, 204, 204, 0.6);
  margin-top: 25px;
  text-align: center;
}

.qrcode-container p:nth-child(3) {
  margin-bottom: 15px;
  padding-bottom: 20px;
  margin-top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
}

.qrcode-container p span.code {
  font-weight: 900;
  color: var(--default);
  text-transform: uppercase;
}

.qrcode-container p strong {
  font-weight: 900;
  color: rgba(204, 204, 204, 0.9);
}

.qrcode-container .qrcode {
  padding: 35px 20px;
  background: #32393d;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-container .qrcode button {
  margin-top: 25px;
  width: 100%;
}
</style>

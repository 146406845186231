<template>
  <div>
    <base-card>
      <base-label :text="StringLanguage.userInformation" />

      <custom-form ref="userInformationForm">
        <name-input v-model="user.name" />
        <email-input v-model="user.email" :required="false" />
        <cellphone-input v-model="user.phone" :required="false" />
      </custom-form>
    </base-card>

    <base-card>
      <base-label :text="StringLanguage.accessData" />

      <custom-form ref="accessDataForm">
        <username-input
          ref="username"
          v-model="user.username"
          :disabled="!!selectedUser.id"
        />
        <password-input
          v-model="user.password"
          :required="selectedUser.id ? false : true"
        />
      </custom-form>
    </base-card>
  </div>
</template>
<script>
import { UsersAPI } from '@/api/Users';

import BaseCard from '@/components/Card';
import BaseLabel from '@/components/Label';
import CustomForm from '@/containers/Form.vue';
import EmailInput from '../Inputs/EmailInput';
import NameInput from '../Inputs/NameInput';
import CellphoneInput from '../Inputs/CellphoneInput';
import UsernameInput from '../Inputs/UsernameInput';
import PasswordInput from '../Inputs/DoublePwInput';

export default {
  props: {
    selectedUser: {},
    userError: { type: Boolean, default: false },
  },
  data() {
    return {
      user: {
        type: 'ADMIN',
        name: '',
        phone: '',
        username: '',
        password: {},
      },
    };
  },
  created() {
    if (Object.keys(this.selectedUser).length > 0) {
      this.user = { ...this.selectedUser, password: {} };
    }
  },
  methods: {
    soe() {
      if (this.isInputsValid()) {
        if (this.selectedUser.id) {
          this.edit();
          return;
        }
        this.save();
      }
    },
    isInputsValid() {
      this.$refs.userInformationForm.validateInputs();
      this.$refs.accessDataForm.validateInputs();

      const isInputsValids =
        !this.$refs.userInformationForm.messages.length &&
        !this.$refs.accessDataForm.messages.length;

      if (this.selectedUser.id) {
        return isInputsValids;
      }

      return isInputsValids;
    },
    async save() {
      // if (await this.isUsernameAvaialble()) {
      const self = this;

      UsersAPI.saveUsers(self.user)
        .then((response) => {
          if (response.getErrors([]).length > 0) {
            alert(response.getErrors([])[0]);
          } else {
            this.user = {};
            self.$emit('newUser', response.getData({ create: null }));
          }
        })
        .catch((err) => {
          alert(StringLanguage.saveError);
        });
    },

    edit() {
      const user = { ...this.user };

      if (!user.password || Object.keys(user.password).length === 0) {
        delete user.password;
      } else if (user.password) {
        user.confirmPassword = user.password;
      }
      user.type = user.Role;
      confirm(StringLanguage.confirmUpdateUser, (res) => {
        if (!res) return;
        UsersAPI.putUser(user.id, user)
          .then((response) => {
            if (response.getErrors([]).length > 0) {
              alert(response.getErrors([])[0]);
            } else {
              this.$emit('editUser', response.getData({ create: null }));
            }
          })
          .catch((err) => {
            alert(StringLanguage.saveError);
          });
      });
    },
    del() {
      confirm(StringLanguage.confirmDisassociateUser, (res) => {
        if (!res) return;

        const self = this;
        UsersAPI.DispatchUser(this.selectedUser.id).then((response) => {
          if (response._errors.length > 0) {
            alert(response._errors[0]);
          } else {
            alert('Usuário removido com sucesso!');
            self.$emit('userDeleted');
          }
        });
      });
    },
  },
  components: {
    BaseCard,
    BaseLabel,
    CustomForm,
    EmailInput,
    NameInput,
    CellphoneInput,
    UsernameInput,
    PasswordInput,
  },
};
</script>

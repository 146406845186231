<template>
  <div>
    <base-card>
      <base-label text="Insira o código do benefício" />
      <base-input
        label="Código do benefício"
        class="mb_0"
        v-model="benefit.code"
        @keyup="selectBenefit(benefit)"
        @blur="selectBenefit(benefit)"
        maxlength="10"
      />
      <base-message v-if="showCodeLimit" class="mt_10">
        Limite de 10 caracteres
      </base-message>
    </base-card>
    <base-card>
      <base-label text="Determine o tipo de cupom" />
      <base-radio
        label="Uso único"
        family="sizeslocation"
        class="mb_15"
        :value="benefit.single_use"
        :option="true"
        @click="benefit.single_use = true"
      />
      <base-radio
        label="Uso múltiplo"
        family="sizeslocation"
        :value="benefit.single_use"
        :option="false"
        @click="benefit.single_use = false"
      />
    </base-card>
    <base-card>
      <base-label text="Determine o valor do benefício e o pedido mínimo" />
      <div class="d-flex" v-if="benefits.type != 'FREE_DELIVERY'">
        <base-input
          label="Valor do beneficio"
          class="w-100 mb_0"
          v-model="benefit.value"
          @keyup="selectBenefit(benefit)"
          :money-mask="
            ['PERCENTAGE_DISCOUNT', 'CASHBACK'].indexOf(benefits.type) !== -1
              ? StringLanguage.percentConfigs
              : StringLanguage.currencyConfigs
          "
          :max="10"
        />

        <base-input
          label="Pedido mínimo"
          class="w-100 ml_15 mb_0"
          v-model="benefit.min_order"
          @keyup="selectBenefit(benefit)"
          :money-mask="StringLanguage.currencyConfigs"
        />
      </div>
      <div class="d-flex" v-else>
        <base-input
          label="Pedido mínimo"
          class="w-100 mb_0"
          v-model="benefit.min_order"
          @keyup="selectBenefit(benefit)"
          :money-mask="StringLanguage.currencyConfigs"
        />
      </div>
    </base-card>
    <base-card>
      <base-label
        :text="
          benefits.type === 'CASHBACK'
            ? 'Defina a expiração de ganho e de gasto deste benefício'
            : 'Defina a expiração do benefício'
        "
      />
      <base-input
        :placeholder="
          benefits.type === 'CASHBACK'
            ? '--- dias de expiração de ganho'
            : '--- dias'
        "
        v-model="benefit.expiration_days"
        :thousandSeparator="false"
        :moneyMask="benefits.type === 'CASHBACK'
            ? StringLanguage.timeConfigs.days_expiration : StringLanguage.timeConfigs.days"
        @keyup="selectBenefit(benefit)"
        max="35"
      />

      <base-input
        v-if="benefits.type === 'CASHBACK'"
        placeholder="--- dias de expiração de gasto"
        v-model="benefit.spend_days"
        :thousandSeparator="false"
        :moneyMask="StringLanguage.timeConfigs.days_spend"
        @keyup="selectBenefit(benefit)"
        max="35"
      />

      <base-message>
        A expiração deste benefício inicia a partir do momento que o usuário o
        recebe.
      </base-message>
    </base-card>
  </div>
</template>

<script>
import BaseLabel from '@/components/Label';
import BaseCard from '@/components/Card';
import BaseInput from '@/components/Input';
import BaseMessage from '@/components/Message';
import BaseRadio from '@/components/Radio';

export default {
  data() {
    return {
      loading: false,
      step: 0,
      validate: true,
      showCodeLimit: false,
      showQrCode: false,
      benefit: this.benefits,
      benefitTypes: {
        discount: 'CASH_DISCOUNT',
        percent: 'PERCENTAGE_DISCOUNT',
        delivery: 'FREE_DELIVERY',
      },
      useTypes: {},
    };
  },

  methods: {
    selectBenefit(value) {
      const formattedValue =
        value.code && value.code.toUpperCase().replace(/[^A-Z0-9]/gi, '');
      this.showCodeLimit = formattedValue && formattedValue.length === 10;
      this.benefit = { ...value, code: formattedValue };
      this.$emit('addPayload', this.benefit);
    },
  },
  props: {
    benefits: { type: Object },
  },
  components: {
    BaseLabel,
    BaseInput,
    BaseCard,
    BaseMessage,
    BaseRadio,
  },
  watch: {
    benefits: {
      deep: true,
      handler(value) {
        this.benefit = value;
      },
    },
  },
};
</script>

<style scoped>
.grid-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.grid-options .option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--box-up);
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
}

.grid-options .option i {
  color: #6f767e;
  font-size: 25px;
  margin-bottom: 15px;
}

.grid-options .option p {
  color: #aaaaab;
  font-size: 12px;
  line-height: 15px;
}

.grid-options .option.active :is(p, i) {
  color: var(--default);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 350px);
}

.qrcode-container {
  padding: 30px 20px 20px;
  background: #2d3338;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-container p {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(204, 204, 204, 0.6);
  margin-top: 25px;
  text-align: center;
}

.qrcode-container p:nth-child(3) {
  margin-bottom: 15px;
  padding-bottom: 20px;
  margin-top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
}

.qrcode-container p span.code {
  font-weight: 900;
  color: var(--default);
  text-transform: uppercase;
}

.qrcode-container p strong {
  font-weight: 900;
  color: rgba(204, 204, 204, 0.9);
}

.qrcode-container .qrcode {
  padding: 35px 20px;
  background: #32393d;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-container .qrcode button {
  margin-top: 25px;
  width: 100%;
}
</style>

<template>
  <div class="user-card" @click="edit(oUser)">
    <div class="d-flex">
      <p class="user-icon">
        <i class="far fa-user-circle" />
      </p>
      <div class="user-info">
        <p v-if="oUser.name" class="user-name">{{ oUser.name }}</p>
        <p class="user-alias">{{ oUser.username }}</p>
      </div>
    </div>

    <div class="user-type">
      <p>Administrador</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    oUser: {},
    currentUser: {},
  },
  methods: {
    edit(selectedUser) {
      const self = this;
      // se o usuário tentar editar ele mesmo
      if (self.currentUser.id === selectedUser.id) {
        alert(StringLanguage.editOwnUserError);
        return false;
      }

      self.$emit('editUser', selectedUser);
    },
  },
};
</script>
<style scoped>
.user-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  background: var(--box);
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.user-icon {
  font-size: 16px;
  color: rgba(111, 118, 126, 0.55);
  background: rgba(46, 52, 56, 0.55);
  margin-right: 20px;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.user-name {
  font-family: 'Rubik', sans-serif;
  font-weight: bold;
  color: var(--label-secondary);
  line-height: 15px;
  font-size: 12px;
  margin-bottom: 5px;
}

.user-alias {
  font-size: 12px;
  line-height: 15px;
  color: rgba(204, 204, 204, 0.65);
  font-weight: 300;
}

.user-type {
  padding-left: 15px;
  border-left: 1px solid rgba(111, 118, 126, 0.17);
}

.user-type p {
  font-size: 12px;
  line-height: 15px;
  background: rgba(46, 52, 56, 0.35);
  padding: 5px 15px;
  border-radius: 10px;
  color: rgba(204, 204, 204, 0.65);
}
</style>

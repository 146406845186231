<template>
  <base-drawer
    title="Tokens de acesso"
    :open="open"
    :close="close"
  >
    <base-card>
      <div class="tokens">
        <p>CLIENT ID</p>
        <div class="code">{{ source.client_id }}</div>
        <p>CLIENT SECRET</p>
        <div class="code">{{ source.client_secret }}</div>
        <p v-if="['e-deploy', 'konv-api'].includes(source.origin) && source.api_key">API KEY</p>
        <div v-if="['e-deploy', 'konv-api'].includes(source.origin) && source.api_key" class="code">
          {{source.api_key}}
        </div>
      </div>
    </base-card>
  </base-drawer>
</template>

<script>
import { mapState } from 'vuex';

import BaseDrawer from '@/components/Drawer';
import BaseCard from '@/components/Card';

const components = {
  BaseDrawer,
  BaseCard
};

export default {
  components,
  props: {
    source: { type: Object, required: true },
    open: { type: Boolean },
    close: { type: Boolean },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>

<style scoped>
.sources {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.sources .source {
  background: var(--box);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sources .source .logo {
  width: 50px;
  height: 50px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  border-radius: 10px;
  background: #333a4059;
}

.sources .source .logo img {
  max-width: 100%;
}

.sources .source .name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 15px;
  color: #aaaaab;
}

.tokens {
  background: #2d3338;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 35px;
}

.tokens p {
  color: #aaaaab;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 20px;
}

.tokens p:not(:first-child) {
  border-top: 1px solid #333a40;
  padding-top: 20px;
  margin-top: 20px;
  width: 100%;
}

.tokens .code {
  word-break: break-all;
  max-width: 100%;
  background: #282d30;
  color: #cccccc;
  font-size: 12px;
  line-height: 14px;
  border-radius: 10px;
  opacity: 0.8;
  padding: 15px 13px;
  display: flex;
  align-items: center;
}

.instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.instructions p {
  color: #aaaaab;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 17px;
}

.instructions a {
  color: var(--default);
  text-decoration: none;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 4px;
  border-bottom: 1px solid;
}

.status-import {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #2d3338;
  padding: 30px 15px;
  border-radius: 10px;
}

.status-import i {
  font-size: 30px;
  margin-bottom: 25px;
}

.status-import i.success {
  color: #82d997;
}

.status-import i.error {
  color: #6f767e;
}

.status-import p {
  color: #cccccc;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  opacity: 0.75;
}

.loading {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .sources {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}
</style>
